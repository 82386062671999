const API_NAMESPACE = '/v1';
const PRO_PLATFORM_NAMESPACE = `${API_NAMESPACE}/pro`;
const ADMIN_NAMESPACE = `${API_NAMESPACE}/admin`;
const CONSUMER_NAMESPACE = `${API_NAMESPACE}/consumer`;

enum ConsumerEndpoints {
  applications = 'applications',
}

const consumerUrls: { [key in ConsumerEndpoints]: string } = {
  applications: `${CONSUMER_NAMESPACE}/applications`,
};

enum ProEndpoints {
  accessToken = 'accessToken',
  applications = 'applications',
  applicationsAssignee = 'applicationsAssignee',
  leads = 'leads',
  leadsAssign = 'leadsAssign',
  leadsCommunication = 'leadsCommunication',
  leadStatuses = 'leadStatuses',
  userNotificationPreferences = 'userNotificationPreferences',
  assistedApplicationLaunch = 'assistedApplicationLaunch',
  assistedApplicationAnswers = 'assistedApplicationAnswers',
  applicationsAttachments = 'applicationsAttachments',
  applicationFiles = 'applicationFiles',
  applicationCopies = 'applicationCopies',
  identityVerification = 'identityVerification',
  agentLicenseValidation = 'agentLicenseValidation',
  dataExtract = 'dataExtract',
  jetWidgetOutcomes = 'jetWidgetOutcomes',
  applicationsOutcomes = 'applicationsOutcomes',
  questionnaires = 'questionnaires',
  notifications = 'notifications',
}

const proUrls: { [key in ProEndpoints]: string } = {
  accessToken: `${PRO_PLATFORM_NAMESPACE}/leads/access-token`,
  applications: `${PRO_PLATFORM_NAMESPACE}/applications`,
  applicationsAssignee: `${PRO_PLATFORM_NAMESPACE}/applications/assignee`,
  applicationsOutcomes: `${PRO_PLATFORM_NAMESPACE}/applications/outcomes`,
  leads: `${PRO_PLATFORM_NAMESPACE}/leads`,
  leadsAssign: `${PRO_PLATFORM_NAMESPACE}/leads/assign`,
  leadsCommunication: `${PRO_PLATFORM_NAMESPACE}/leads/communication`,
  leadStatuses: `${PRO_PLATFORM_NAMESPACE}/leadStatuses`,
  userNotificationPreferences: `${PRO_PLATFORM_NAMESPACE}/users/notificationPreferences`,
  assistedApplicationLaunch: `${PRO_PLATFORM_NAMESPACE}/assisted-application/launch`,
  assistedApplicationAnswers: `${PRO_PLATFORM_NAMESPACE}/assisted-application/answers`,
  applicationsAttachments: `${PRO_PLATFORM_NAMESPACE}/applications/attachments`,
  applicationFiles: `${PRO_PLATFORM_NAMESPACE}/applications/files`,
  applicationCopies: `${PRO_PLATFORM_NAMESPACE}/applications/copies`,
  identityVerification: `${PRO_PLATFORM_NAMESPACE}/identityVerification`,
  agentLicenseValidation: `${PRO_PLATFORM_NAMESPACE}/agentLicenseValidation`,
  dataExtract: `${PRO_PLATFORM_NAMESPACE}/dataExtract`,
  jetWidgetOutcomes: `${PRO_PLATFORM_NAMESPACE}/jet-widget/outcomes`,
  questionnaires: `${PRO_PLATFORM_NAMESPACE}/questionnaires`,
  notifications: `${PRO_PLATFORM_NAMESPACE}/users/:userId/notifications`,
};

enum AdminEndpoints {
  bulkUsers = 'bulkUsers',
  settings = 'settings',
  insuranceFirms = 'insuranceFirms',
  insuranceProducts = 'insuranceProducts',
  insuranceProductPricing = 'insuranceProductPricing',
  questionnaireVersions = 'questionnaireVersions',
  publishQuestionnaireVersion = 'publishQuestionnaireVersion',
  newDraftQuestionnaireVersion = 'newDraftQuestionnaireVersion',
  questionnaireBuilder = 'questionnaireBuilder',
  salesDecisionRules = 'salesDecisionRules',
  users = 'users',
  userInvitations = 'userInvitations',
  userPermissions = 'userPermissions',
  usersSync = 'usersSync',
  auditLogs = 'auditLogs',
  applicationSchemas = 'applicationSchemas',
  questionnairePreview = 'questionnairePreview',
  exportData = 'exportData',
  importData = 'importData',
  thirdPartyIntegrations = 'thirdPartyIntegrations',
  importCopyDeck = 'importCopyDeck',
  applicationSupportApplications = 'applicationSupportApplications',
  applicationSupportApplicationsSubmissionReset = 'applicationSupportApplicationsSubmissionReset',
  applicationSupportApplicationsSubmissionDownloadFiles = 'applicationSupportApplicationsSubmissionDownloadFiles',
  applicationSupportUnderwritingReports = 'applicationSupportUnderwritingReports',
  applicationSupportESignaturesCeremonies = 'applicationSupportESignaturesCeremonies',
  applicationSupportAssociatedFiles = 'applicationSupportAssociatedFiles',
  utilitiesTypeDeclarations = 'utilitiesTypeDeclarations',
  latestQuestionnaire = 'latestQuestionnaire',
  questionnaires = 'questionnaires',
  linesOfBusiness = 'linesOfBusiness',
  questionnaireSchemas = 'questionnaireSchemas',
  userGroups = 'userGroups',
  userUserGroups = 'userUserGroups',
  manualSubmissions = 'manualSubmissions',
  adHocSignatureRequests = 'adHocSignatureRequests',
  questionnaireNodeIds = 'questionnaireNodeIds',
}

const adminUrls: { [key in AdminEndpoints]: string } = {
  manualSubmissions: `${ADMIN_NAMESPACE}/manualSubmissions`,
  insuranceProducts: `${ADMIN_NAMESPACE}/insuranceProducts`,
  insuranceProductPricing: `${ADMIN_NAMESPACE}/insuranceProductPricing`,
  insuranceFirms: `${ADMIN_NAMESPACE}/insuranceFirms`,
  salesDecisionRules: `${ADMIN_NAMESPACE}/salesDecisionRules`,
  settings: `${ADMIN_NAMESPACE}/settings`,
  questionnairePreview: `${ADMIN_NAMESPACE}/applications/preview`,
  questionnaireVersions: `${ADMIN_NAMESPACE}/questionnaires/questionnaireVersions`,
  publishQuestionnaireVersion: `${ADMIN_NAMESPACE}/questionnaires/questionnaireVersions/publish`,
  newDraftQuestionnaireVersion: `${ADMIN_NAMESPACE}/questionnaires/questionnaireVersions/newDraft`,
  latestQuestionnaire: `${ADMIN_NAMESPACE}/questionnaires/latest`,
  questionnaires: `${ADMIN_NAMESPACE}/questionnaires`,
  questionnaireNodeIds: `${ADMIN_NAMESPACE}/questionnaires/nodeIds`,
  linesOfBusiness: `${ADMIN_NAMESPACE}/linesOfBusiness`,
  questionnaireBuilder: `${ADMIN_NAMESPACE}/questionnaireBuilder`,
  bulkUsers: `${ADMIN_NAMESPACE}/users/bulk`,
  userInvitations: `${ADMIN_NAMESPACE}/users/invitation`,
  userPermissions: `${ADMIN_NAMESPACE}/user/permissions`,
  usersSync: `${ADMIN_NAMESPACE}/users/sync`,
  users: `${ADMIN_NAMESPACE}/users`,
  auditLogs: `${ADMIN_NAMESPACE}/audit-logs`,
  applicationSchemas: `${ADMIN_NAMESPACE}/applicationSchemas`,
  exportData: `${ADMIN_NAMESPACE}/exportData`,
  importData: `${ADMIN_NAMESPACE}/importData`,
  thirdPartyIntegrations: `${ADMIN_NAMESPACE}/third-party-integrations`,
  importCopyDeck: `${ADMIN_NAMESPACE}/import/copyDeck`,
  applicationSupportApplications: `${ADMIN_NAMESPACE}/application-support/applications`,
  applicationSupportApplicationsSubmissionReset: `${ADMIN_NAMESPACE}/application-support/applications/submission/reset`,
  applicationSupportApplicationsSubmissionDownloadFiles: `${ADMIN_NAMESPACE}/application-support/applications/submission/downloadFiles`,
  applicationSupportESignaturesCeremonies: `${ADMIN_NAMESPACE}/application-support/esignatures/ceremonies`,
  applicationSupportUnderwritingReports: `${ADMIN_NAMESPACE}/application-support/underwriting/outcomes`,
  applicationSupportAssociatedFiles: `${ADMIN_NAMESPACE}/application-support/associated-files`,
  utilitiesTypeDeclarations: `${ADMIN_NAMESPACE}/utilities-type-declarations`,
  questionnaireSchemas: `${ADMIN_NAMESPACE}/questionnaireSchemas`,
  userGroups: `${ADMIN_NAMESPACE}/userGroups`,
  userUserGroups: `${ADMIN_NAMESPACE}/userUserGroups`,
  adHocSignatureRequests: `${ADMIN_NAMESPACE}/signature/adhoc-requests`,
};

enum SharedEndpoints {
  theme = 'theme',
  settings = 'settings',
  applications = 'applications',
  cryptoSignature = 'cryptoSignature',
  eSignCeremonies = 'eSignCeremonies',
  eSignFieldSettings = 'eSignFieldSettings',
  onespanEventCallback = 'onespanEventCallback',
  externalSignature = 'externalSignature',
  products = 'products',
  legacyQuotes = 'legacyQuotes',
  quotes = 'quotes',
  totalPremiums = 'totalPremiums',
  submission = 'submission',
  parties = 'parties',
  pdf = 'pdf',
  carrierQuestionnaires = 'carrierQuestionnaires',
  questionnaireLandingStep = 'questionnaireLandingStep',
  addons = 'addons',
  debugToolbarApplications = 'debugToolbarApplications',
  debugToolbarLeads = 'debugToolbarLeads',
  questions = 'questions',
  recommendedCoverage = 'recommendedCoverage',
  summary = 'summary',
  dynamicPdfData = 'dynamicPdfData',
  paymentClover = 'paymentClover',
  fileTemplates = 'fileTemplates',
  fileTemplateRules = 'fileTemplateRules',
  participantRoles = 'participantRoles',
  participants = 'participants',
  participantsProcessor = 'participantsProcessor',
  pointOfSaleDecision = 'pointOfSaleDecision',
  fileTemplateRecipients = 'fileTemplateRecipients',
  paymentTransactions = 'paymentTransactions',
  pricingFieldIdentifiers = 'pricingFieldIdentifiers',
  productsEntity = 'productsEntity',
}

const sharedUrls: { [key in SharedEndpoints]: string } = {
  theme: `${API_NAMESPACE}/theme`,
  settings: `${API_NAMESPACE}/settings`,
  products: `${API_NAMESPACE}/products`,
  addons: `${API_NAMESPACE}/products/addons`,
  applications: `${API_NAMESPACE}/applications`,
  cryptoSignature: `${API_NAMESPACE}/applications/signature/crypto`,
  eSignCeremonies: `${API_NAMESPACE}/applications/signature/esignature/ceremonies`,
  eSignFieldSettings: `${API_NAMESPACE}/applications/signature/esignature/settings/fields`,
  fileTemplateRecipients: `${API_NAMESPACE}/applications/signature/esignature/settings/recipients`,
  onespanEventCallback: `${API_NAMESPACE}/applications/onespan/event`,
  externalSignature: `${API_NAMESPACE}/applications/signature/external`,
  legacyQuotes: `${API_NAMESPACE}/legacy/quotes`,
  quotes: `${API_NAMESPACE}/quotes`,
  totalPremiums: `${API_NAMESPACE}/totalPremiums`,
  submission: `${API_NAMESPACE}/needsAnalysis/submission`,
  parties: `${API_NAMESPACE}/parties`,
  pdf: `${API_NAMESPACE}/pdf`,

  carrierQuestionnaires: `${API_NAMESPACE}/carrierQuestionnaires`,
  questionnaireLandingStep: `${API_NAMESPACE}/questionnaireLandingStep`,
  questions: `${API_NAMESPACE}/questions`,
  recommendedCoverage: `${API_NAMESPACE}/recommendedCoverage`,
  summary: `${API_NAMESPACE}/summary`,
  debugToolbarApplications: `${API_NAMESPACE}/debugtoolbar/applications`,
  debugToolbarLeads: `${API_NAMESPACE}/debugtoolbar/leads`,
  dynamicPdfData: `${API_NAMESPACE}/pdf/dynamicPdfData`,
  paymentClover: `${API_NAMESPACE}/payments/cloverpay`,

  // File Template
  fileTemplates: `${API_NAMESPACE}/files/templates`,
  fileTemplateRules: `${API_NAMESPACE}/files/templates/rules`,

  // -- Participants --
  participants: `${API_NAMESPACE}/participants`,
  // Participant Roles
  participantRoles: `${API_NAMESPACE}/participants/roles`,

  // participants processor
  participantsProcessor: `${API_NAMESPACE}/participantsProcessor`,

  //Point of sale decisions
  pointOfSaleDecision: `${API_NAMESPACE}/pointOfSaleDecision`,

  //Payment transaction
  paymentTransactions: `${API_NAMESPACE}/payments/transactions`,
  pricingFieldIdentifiers: `${API_NAMESPACE}/pricing/node-ids`,
  productsEntity: `${API_NAMESPACE}/pricing/productsEntity`,
};

export default {
  admin: adminUrls,
  pro: proUrls,
  consumer: consumerUrls,
  shared: sharedUrls,
};
